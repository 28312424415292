import { QueryClientProvider } from '@tanstack/react-query';
import type { ReactNode } from 'react';

import { defaultQueryClient } from '@endaoment-frontend/data-fetching';
import { StylesProvider } from '@endaoment-frontend/ui/shared';

export const AppProvider = ({ children }: { children?: Array<ReactNode> | ReactNode }) => {
  return (
    <StylesProvider>
      {/* QueryClientProvider MUST be above WagmiProvider */}
      <QueryClientProvider client={defaultQueryClient}>{children}</QueryClientProvider>
    </StylesProvider>
  );
};
