import type { AppProps as NextAppProps } from 'next/app';

import { AppProvider } from '../components/AppProvider';

import './app.css';

const App = ({ Component, pageProps }: NextAppProps) => {
  return (
    <AppProvider>
      <main>
        <Component {...pageProps} />
      </main>
    </AppProvider>
  );
};

export default App;
